import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Navigation } from 'swiper/modules'

import { Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image'

import Character from '../character'

import { CharactersProps } from './types'
import { useApp } from '@/core/contexts/app'
import { path } from '@/core/utils'

const Characters: React.FunctionComponent<CharactersProps> = ({
  characters = [],
  background,
  leftImage,
  rightImage,
}) => {
  const app = useApp()

  return (
    <div className="characters">
      <div
        className="shape shape--bg"
        style={{
          backgroundImage: `url('${path.asset(
            app.environment,
            background?.src
          )}')`,
          backgroundSize: 'cover',
        }}
      />
      <div className="shape shape--left">
        <Image {...leftImage} alt="" />
      </div>
      <Container size="wide">
        <Row>
          <div className="swiper-button-prev slider-arrow"></div>
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              enabled: true,
            }}
            modules={[EffectCoverflow, Navigation]}
            className="swiper_container"
          >
            {characters.map((item, index) => (
              <SwiperSlide key={index}>
                <Character {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-next slider-arrow"></div>
        </Row>
      </Container>
      <div className="shape shape--right">
        <Image {...rightImage} alt="" />
      </div>
    </div>
  )
}

export default Characters
