import React from 'react'
import { CharacterProps } from './types'
import Image from '@/components/base/image'

const Character: React.FunctionComponent<CharacterProps> = ({
  title,
  image,
  desc,
  bgTop,
  bgBottom,
}) => {
  return (
    <div
      className="character"
      style={{
        background: `linear-gradient(180deg, ${bgTop} 0%, ${bgBottom} 100%)`,
      }}
    >
      <figure>
        <Image {...image} fluid alt={title} />
      </figure>
      <div className="detail">
        <div
          className="title"
          style={{
            textShadow: `${bgBottom} 3px 0px 0px, ${bgBottom} 2.83487px 0.981584px 0px, ${bgBottom} 2.35766px 1.85511px 0px, ${bgBottom} 1.62091px 2.52441px 0px, ${bgBottom} 0.705713px 2.91581px 0px, ${bgBottom} -0.287171px 2.98622px 0px, ${bgBottom} -1.24844px 2.72789px 0px, ${bgBottom} -2.07227px 2.16926px 0px, ${bgBottom} -2.66798px 1.37182px 0px, ${bgBottom} -2.96998px 0.42336px 0px, ${bgBottom} -2.94502px -0.571704px 0px, ${bgBottom} -2.59586px -1.50383px 0px, ${bgBottom} -1.96093px -2.27041px 0px, ${bgBottom} -1.11013px -2.78704px 0px, ${bgBottom} -0.137119px -2.99686px 0px, ${bgBottom} 0.850987px -2.87677px 0px, ${bgBottom} 1.74541px -2.43999px 0px, ${bgBottom} 2.44769px -1.73459px 0px, ${bgBottom} 2.88051px -0.838247px 0px`,
          }}
        >
          {title}
        </div>
        {desc && (
          <div className="desc" dangerouslySetInnerHTML={{ __html: desc }} />
        )}
      </div>
    </div>
  )
}

export default Character
